<template>
  <NuxtLink
    v-if="!!blok.url?.cached_url"
    :to="blok.url.cached_url"
    :class="$style['link-image']"
    :target="blok.url.target">
    <NuxtImg
      format="webp"
      quality="50"
      :alt="blok.image?.alt"
      :src="blok.image?.filename" />
  </NuxtLink>
  <NuxtImg
    v-else
    format="webp"
    quality="50"
    :alt="blok.image?.alt"
    :src="blok.image?.filename" />
</template>
<script setup>
defineProps({ blok: Object })
</script>
<style  module>
.link-image {
  display: inline-flex;
  width: 100%;
  height: 100%;
}

.link-image > * {
  object-fit: cover;
  object-position: 50% 25%;
  width: 100%;
}
</style>
